import {useState} from 'react'
import './App.css';
import axios from 'axios';
import {Container, Spinner, Button, Alert } from 'react-bootstrap';
import logo from './img/mob-logo_Grupo_350.png'

function App() {
   const [alertProceso, setAlertProceso] = useState(false)
   const [alertSuccess, setAlertSuccess] = useState(false)
   const [alertError, setAlertError] = useState(false)

   const actualizarStock = () =>{
      setAlertProceso(true)
      axios.post('https://productosmanualidades.com/api/productos/stock')
      // axios.post('http://localhost:4001/api/productos/stock')gitcommit*Co3
         .then((response) => {
            console.log(response);
            setTimeout(() => {
               setAlertProceso(false)
               setAlertSuccess(true)
               setTimeout(() => {
                  setAlertProceso(false)
                  setAlertSuccess(false)
               }, 4000);
            }, 200000);
         })
         .catch((error) => {
            setAlertError(true)
            setAlertProceso(false)
            setTimeout(() => {
               setAlertError(false)
            }, 3000);
            console.log('Hubo un error. '+error);
         });
   }
   return (
      <>
         <Container className="contenedor">
            <header className="header_logo">
               <img src={logo} alt={"logo"}/>
            </header>
            {
               (alertProceso)  ?  <section className="seccion_alert">
                              <Spinner className="spinner_actualizando_stock" animation="border" variant="primary" /> Actualizando Stock...
                           </section>
                        :  <section className="seccion_boton">
                              <button className="boton_actualizar" onClick={actualizarStock}>Actualizar Stock</button>
                           </section>
            }
            <section className="seccion_alert">
               {
                  (alertSuccess) && <Alert variant='success' className=''>
                                       Stock actualizado con éxito.
                                    </Alert>
               }{
                  (alertError) &&   <Alert variant='danger' className=''>
                                       Error al conectar con la DB.
                                    </Alert>
               }
            </section>
         </Container>
      </>
   );
}

export default App;